import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Address } from './address';
import { CustomUnitFieldValue } from './custom-unit-field-value';
import { Image } from './image';
import { Organization } from './organization';
import { Owner } from './owner';
import { Tag } from './tag';
import { Charge } from './charge';
import { AuxiliaryContact } from './auxiliary-contact';
import { OrganizationFile } from './organization-file';
import { RecurringChargeTemplate } from './recurring-charge-template';
import { ViolationStatement } from 'app/models/entities/violation-statement';
import { UnitOccupancy } from './unit-occupancy';
import { PaginationLinks, PaginationMeta } from './pagination';
import { FormSubmission } from './forms/form-submission';
import Moment = moment.Moment;

export class Unit
{
    id: number;
    organizationId: number;
    name: string;
    title: string;
    description: string;
    balance: number;
    pastDueBalance: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    organization: Organization;
    address: Address;
    owners: Owner[] = [];
    customUnitFieldValues: CustomUnitFieldValue[] = [];
    images: Image[] = [];
    tags: Tag[] = [];
    ownerTags: Tag[] = [];
    auxContacts: AuxiliaryContact[];
    charges?: Charge[] = [];
    documents: OrganizationFile[];
    recurringChargeTemplates: RecurringChargeTemplate[];
    currentOccupancy: UnitOccupancy = null;

    violations: ViolationStatement[];
    openViolationsTotal = 0;
    formSubmissions: FormSubmission[] = [];

    formSubmissionsByType: { [key: string]: FormSubmission[]; } = {};
    openFormSubmissionsByTypeTotal: { [key: string]: number; } = {};
    openFormSubmissionsTotal = 0;

    checked?: boolean;
    autopayEnabled?: boolean = false;
    autopayTotal: number = 0;
    autopaidTotal: number = 0;
    addressString?: string = '';
    ownerString?: string = '';
    ownerEmailString: string = '';

    recurringChargesTotal = 0;
    recurringChargesCommonBasis: string = '';

    constructor(unit: any) {
        if (unit.id) {
            this.id = unit.id;
        }
        if (unit.organizationId) {
            this.organizationId = unit.organizationId;
        }

        this.name = unit?.name ? unit.name : unit.title;

        if (unit.address) {
            this.address = new Address(unit.address);
            this.addressString = this.address.formatAddressString();
        }

        if (unit.hasOwnProperty('balance')) {
            this.balance = unit.balance;
        }

        if (unit.owners) {
            this.owners = unit.owners.map(owner => {
                return new Owner(owner);
            });
            this.autopayEnabled = false;
            this.autopayTotal = 0;
            this.autopaidTotal = 0;

            this.owners.forEach((owner) => {
                if (owner.membership && owner.membership.profile && owner.membership.profile.autopayEnabled) {
                    this.autopayEnabled = true;
                    this.autopayTotal += owner.membership.profile.autopayMax;
                    this.autopaidTotal += owner.thisMonthAutopaidTotal;
                }
                if (owner.membership?.tags) {
                    this.ownerTags = this.ownerTags.concat(owner.membership.tags);
                }
            });

            this.ownerString = this.owners.filter(owner => owner.name).map(owner => {
                return owner.name.charAt(0).toUpperCase() + owner.name.slice(1);
            }).join(', ');
            this.ownerEmailString = this.owners.filter(owner => owner.membership?.user?.email).map(owner => {
                return owner.membership.user.email || '';
            }).join(', ');
        }

        if (unit.title) {
            this.title = unit.title;
        }

        if (unit.description) {
            this.description = unit.description;
        }

        if (unit.currentOccupancy) {
            this.currentOccupancy = new UnitOccupancy(unit.currentOccupancy);
        }

        const timezone = LegFiJwtService.getTimezone();
        if (unit.createdAt) {
            this.createdAt = moment
                    .utc(unit.createdAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
        if (unit.updatedAt) {
            this.updatedAt = moment
                    .utc(unit.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
        if (unit.deletedAt) {
            this.deletedAt = moment
                    .utc(unit.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }

        if (unit.hasOwnProperty('pastDueBalance')) {
            this.pastDueBalance = unit.pastDueBalance;
        }

        if (unit.organization) {
            this.organization = new Organization(unit.organization);
        }

        if (unit.customUnitFieldValues) {
            this.customUnitFieldValues = unit.customUnitFieldValues.map(
                    customUnitFieldValue => {
                        return new CustomUnitFieldValue(customUnitFieldValue);
                    },
            );
        }

        if (unit.tags) {
            this.tags = unit.tags.map(tag => {
                return new Tag(tag);
            });
        }
        if (unit.recurringChargeTemplates) {
            this.recurringChargeTemplates = unit.recurringChargeTemplates.map(
                    template => {
                        return new RecurringChargeTemplate(template);
                    },
            );
            // Sum all of our templates amounts
            // If they all have the same basis, set a flag for the unit card to display that
            if (this.recurringChargeTemplates.length > 0) {
                this.recurringChargesTotal = this.recurringChargeTemplates.map(rct => rct.pivot.amount).reduce((
                        a,
                        b,
                ) => a + b, 0);
                const bases = this.recurringChargeTemplates.map(rct => rct.recurBasis);
                const firstBasis = this.recurringChargeTemplates[0].recurBasis;
                this.recurringChargesCommonBasis = new Set(bases).size > 1
                        ? 'Total'
                        : firstBasis.charAt(0).toUpperCase() + firstBasis.slice(1);
            }
        }

        if (unit.images) {
            if (unit.images.length === 1) {
                if (
                        unit.images[0].small === '' &&
                        unit.images[0].medium === '' &&
                        unit.images[0].large === ''
                ) {
                    unit.images = [];
                }
            }
            if (unit.images.length > 0) {
                this.images = unit.images.map(image => {
                    return new Image(image);
                });
            } else {
                const bucket = 'https://s3.amazonaws.com/imaging-core/';
                this.images = [
                    new Image({
                        id: -1, // Hard set to -1 in order to hide this from being editable
                        unitId: this.id,
                        small: bucket + 'no-image.png',
                        medium: bucket + 'no-image.png',
                        large: bucket + 'no-image.png',
                    }),
                ];
            }
        }
        if (unit.contacts) {
            this.auxContacts = unit.contacts.map(contact => {
                return new AuxiliaryContact(contact);
            });
        }
        if (unit.charges) {
            this.charges = unit.charges.map(charge => {
                return new Charge(charge);
            });
        }

        if (unit.documents) {
            this.documents = unit.documents.map(document => {
                return new OrganizationFile(document);
            });
        } else {
            this.documents = [];
        }

        if (unit.violations) {
            this.violations = unit.violations.map((violation) => {
                if (!violation.completedTime) {
                    this.openViolationsTotal++;
                }
                return new ViolationStatement(violation);
            });
        }

        if (unit.formSubmissions) {
            this.formSubmissions = unit.formSubmissions.map((submission) => new FormSubmission(submission));
            this.formSubmissionsByType = this.formSubmissions.reduce((carry, sub) => {
                if (sub.form?.name) {
                    if (!sub.completionDate) {
                        this.openFormSubmissionsByTypeTotal[sub.form.name] = this.openFormSubmissionsByTypeTotal[sub.form.name] || 0;
                        this.openFormSubmissionsByTypeTotal[sub.form.name]++;
                    }
                    carry[sub.form.name] = carry[sub.form?.name] || [];
                    carry[sub.form.name].push(sub);
                }
                return carry;
            }, {});
        }
    }

    // unit owners format per new design
    // i.e. John Smith • Jane Smith
    get ownersOneLine() {
        if (this.owners.length > 0) {
            return this.owners.map((owner) => owner.name).join(' • ');
        }

        return '';
    }
}

export class UnitsResponse
{
    data: Unit[];
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map(tx => {
            return new Unit(tx);
        }) : [];
        this.meta = new PaginationMeta(request.meta);
    }
}
